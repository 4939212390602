<template>
  <div class="main_container">
    <div class="row mb-3">
      <div class="text-left col-12">
        <router-link :to="'/analyses/board'">
          Analyses >
        </router-link>
        <span>Business Plan ></span>
      </div>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Business Plan
          </div>
        </div>
      </div>
    </div>
    <div class="row my-4">
      <router-link :to="{ name: 'AddBplan' }"
                   class="btn btn-blue">
        Ajouter un business plan
      </router-link>
    </div>
    <div class="row">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="th-blue"> 
              Libellé
            </th>

            <th class="th-blue">
              Date 
            </th>

            <th class="th-blue">
              VAN
            </th>
            <th class="th-blue">
              TRI
            </th>
          </tr>
        </thead>
        <tbody>
          <tr scope="row">
            <td >FER Exercice comptable 2015</td>
            <td>Lorem ipsum dolor</td>
            <td>Lorem ipsum dolor</td>
            <td>Lorem ipsum dolor</td>
          </tr>
          
        </tbody>
      </table>
    </div>
  </div>
  
</template>
<script>
export default {
  name:"BusinessPlan"
}
</script>